import revive_payload_client_VBKzvMctj2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vite@5.3.3_vue-tsc@2.0.26/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7WLutLn8Je from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vite@5.3.3_vue-tsc@2.0.26/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ieAXvTSzL9 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vite@5.3.3_vue-tsc@2.0.26/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_NxTaKKWtit from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vite@5.3.3_vue-tsc@2.0.26/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_hKZ0TxW6vj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vite@5.3.3_vue-tsc@2.0.26/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_rjtZ1wpIYM from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.18.0_typescript@5.5.3_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_rhgzv4Pe9o from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vite@5.3.3_vue-tsc@2.0.26/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_2VnOBHjIF3 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.31/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_3Z3F9Jv7uE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-primevue@3.0.0_rollup@4.18.0_vue@3.4.31/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_7l6Yg5bxGc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vite@5.3.3_vue-tsc@2.0.26/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import firebase_0ozxroaIkb from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_VbOiz9Kg2G/plugins/firebase.ts";
import middleware_global_oU3EnKP5tV from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_VbOiz9Kg2G/plugins/middleware.global.ts";
import primevue_global_DXZDKbYa3W from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_VbOiz9Kg2G/plugins/primevue.global.ts";
export default [
  revive_payload_client_VBKzvMctj2,
  unhead_7WLutLn8Je,
  router_ieAXvTSzL9,
  payload_client_NxTaKKWtit,
  check_outdated_build_client_hKZ0TxW6vj,
  plugin_vue3_rjtZ1wpIYM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rhgzv4Pe9o,
  i18n_2VnOBHjIF3,
  primevue_plugin_egKpok8Auk,
  plugin_client_3Z3F9Jv7uE,
  chunk_reload_client_7l6Yg5bxGc,
  firebase_0ozxroaIkb,
  middleware_global_oU3EnKP5tV,
  primevue_global_DXZDKbYa3W
]