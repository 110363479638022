import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vite@5.3.3_vue-tsc@2.0.26/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45check_45global from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_VbOiz9Kg2G/middleware/auth-check.global.ts";
import token_45refresh_45global from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_VbOiz9Kg2G/middleware/token-refresh.global.ts";
import trailing_45slash_45global from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_VbOiz9Kg2G/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.3_@opentelemetry+api@1.9.0_eslint@8.57.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vite@5.3.3_vue-tsc@2.0.26/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45check_45global,
  token_45refresh_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}